export namespace Modal {
    export let init = () => {
        const openModal = () => {
            const modalTrigger = document.getElementsByClassName('modal-trigger');

            for(let i = 0; i < modalTrigger.length; i++) {
                modalTrigger[i].addEventListener('click', () => {
                    const target = modalTrigger[i].getAttribute('href').substr(1);
                    const modalWindow = document.getElementById(target);

                    modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
                });
            }
        }

        const closeModal = () => {
            const closeButton = document.getElementsByClassName('modal-close');
            const closeOverlay = document.getElementsByClassName('modal-overlay');

            for(let i = 0; i < closeButton.length; i++) {
                closeButton[i].addEventListener('click', () => {
                    const modalWindow = closeButton[i].parentNode.parentNode as HTMLDivElement;
                    modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                    removeContent();
                });
            }

            for(let i = 0; i < closeOverlay.length; i++) {
                closeOverlay[i].addEventListener('click', () => {
                    const modalWindow = closeOverlay[i].parentNode as HTMLDivElement;
                    modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                    removeContent();
                });
            }
        }

        const removeContent = () => {
            document.getElementById("modalContent").innerHTML = "";
            document.getElementById("modalContainer").classList.remove("form-visible");
            document.getElementById('jsModal').classList.remove("modal-error");
        }

        const ready = (fn) => {
            if (document.readyState != 'loading'){
                fn();
            } else {
                document.addEventListener('DOMContentLoaded', fn);
            }
        }

        ready(openModal);
        ready(closeModal);
    };
}

Modal.init();