export namespace FormValidation {
    export let init = () => {
        const emailField = document.getElementById("email") as HTMLInputElement;
        const button = document.getElementById("validate");
        const response = document.getElementById("emailResponse");

        if(button != null ){
            button.addEventListener("click", function () {
                const email = emailField.value;
                if (!validateEmail(email)) {
                    response.classList.add("visible");
                    response.innerHTML = "Please send an email in the correct format";
                } else {
                    response.classList.remove("visible");
                }
            });
        }

    };
}

FormValidation.init();

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function checkForm(form) {

    if (form.email == '') {
        document.getElementById("email").style.display = "block";
    }
    if (form.message == '') {
        document.getElementById("message").style.display = "block";
    }

    return !(form.email == '' || form.message == '');
}
