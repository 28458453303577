import Lazyload from '../lib/lazyload';

export namespace LazyloadImage {
    let lazy: any;
    let lazyCover: any;

    const LAZYLOAD_OPTIONS = {
        elements_selector: '.lazy',
        class_loading: 'lazyloading',
        class_loaded: 'lazyloaded',
    };

    const LAZYCOVER_OPTIONS = {
        elements_selector: '.lazyCover',
        class_loading: 'lazyloading',
        class_loaded: 'lazyloaded',
    };

    export let init = function() {
        // @ts-ignore
        lazy = new Lazyload(LAZYLOAD_OPTIONS);

        document.addEventListener('DOMContentLoaded', () => {
            // @ts-ignore
            lazyCover = new Lazyload(LAZYCOVER_OPTIONS);
        });
    };
}

LazyloadImage.init();