const insertScript = function (url) {
    const script = document.createElement('script');

    script.async = true;
    script.src = url;

    const promise = new Promise(function (resolve) {
        script.onload = function () {
            script.setAttribute('data-loaded', 'true');
            resolve();
        };
    });

    const firstScript = document.getElementsByTagName('script')[0];

    firstScript.parentNode.insertBefore(script, firstScript);

    return promise;
};

declare global {
    interface Window {
        grecaptcha: any;
    }
}

const RECAPTCHA_SITE_KEY = '6LdwJT0mAAAAAE3K9Bx9WoZlB9WWmnnCnfuilSUL';

export function init() {
    insertScript(`https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`).then(() => {
        console.info('Google recaptcha enterprise script loaded.')
    });
}

export function execute(func: (token: string) => void) {
    window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, {action: 'SEND'});
        // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
        // reCAPTCHA Enterprise to the end user's browser.
        // This token must be validated by creating an assessment.
        // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment

        func(token);

    });
}

init();
