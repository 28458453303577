export namespace Header {
    export let init = () => {
        document.querySelector('.navbar-toggler').addEventListener('click', () => {
            const element = document.getElementById("menu");
            element.classList.toggle("menuMobileVisible");
        })
    };
}

Header.init();
