export namespace Format {
    export let init = () => {
        let els = document.getElementsByClassName('format-episode');

        for (let i = 0; i < els.length; i++) {
            els[i].addEventListener('click', () => {
                const vimeoFrame = document.createElement("iframe");
                vimeoFrame.style["maxWidth"] = '1280px';
                vimeoFrame.style["width"] = '100%';
                vimeoFrame.style["height"] = '100%';
                vimeoFrame.style["borderRadius"] = '14px';
                vimeoFrame.style["marginBottom"] = '20px';
                vimeoFrame.style["pointerEvents"] = 'bounding-box';
                vimeoFrame.style["aspectRatio"] = '16 / 9';
                vimeoFrame.src = 'https://player.vimeo.com/video/' + els[i].getAttribute('data');
                vimeoFrame.frameBorder = "0";
                vimeoFrame.allow = "autoplay; fullscreen; picture-in-picture";
                vimeoFrame.width = "1280";
                vimeoFrame.height = "720";
                vimeoFrame.id = "vimeoFrame";

                const playerDiv = document.createElement("div");
                playerDiv.id = "player";
                playerDiv.className = "player";
                const modalContent = document.getElementById('modalContent');
                modalContent.appendChild(playerDiv);

                if(playerDiv){
                    const player = document.getElementById("player");
                    player.appendChild(vimeoFrame);

                    if (els[i].getAttribute('datalock')  == "true") {
                        const requestButton =  document.createElement("button");
                        requestButton.innerText = "request access";
                        requestButton.className = "button m-auto request-access";
                        requestButton.id = "requestButton";
                        requestButton.addEventListener('click', () => {
                            document.getElementById('modalClose').click();
                            document.getElementById("modalContainer").classList.add("form-visible");
                            document.getElementById("modalTrigger").click();
                        });
                        player.appendChild(requestButton);
                    }
                }
                document.getElementById("modalTrigger").click();
            });
        }
    };
}

Format.init();
