import * as http from 'superagent';
import {checkForm, validateEmail} from "./formValidation";
import * as grecaptcha from './grecaptcha'

export namespace Contact {
    export let init = () => {

        const form6Formats = document.getElementById('faq-form-6Formats') as HTMLFormElement;

        const tooltip = document.getElementById("tooltip") as HTMLDivElement;
        const modalClose = document.getElementById('modalClose') as HTMLDivElement;

        const emailField = document.getElementById("email") as HTMLInputElement;

        if(tooltip){
            const tooltipClose = tooltip.querySelector(".tooltip__close");
            tooltipClose.addEventListener("click", e =>{
                e.preventDefault();
                removeTooltip(tooltip);
            })
        }

        if(form6Formats != null) {
            form6Formats.addEventListener("submit", e => {
                e.preventDefault();

                grecaptcha.execute( (token) => {
                    let data = {
                        "firstname":form6Formats.elements['firstname'].value,
                        "lastname" : form6Formats.elements['lastname'].value,
                        "email" : form6Formats.elements['email'].value,
                        "company" : form6Formats.elements['company'].value,
                        "country" : form6Formats.elements['country'].value,
                        "message" : form6Formats.elements['message'].value,
                        "captcha" : token,
                        "formatName" : form6Formats.elements['formatName'].getAttribute("data-formatName"),
                    }

                    if (checkForm(data)) {
                        if(validateEmail(emailField.value)) {
                            displayTooltip();
                            http.post(form6Formats.action)
                              .send(data)
                              .set('accept', 'json')
                              .then(res => {
                                  displayTooltipSuccess(tooltip);
                                  resetForm();
                                  if(modalClose && tooltip.classList.contains("success")){
                                      modalClose.click();
                                  }
                              })
                              .catch(err => {
                                  removeTooltip(tooltip);
                                  if (err.response.statusCode == 405 || err.response.statusCode == 404) {
                                      setErrors(err.message);
                                  } else if (err.response.statusCode == 400 || err.response.statusCode == 500) {
                                      setErrors(err.message);
                                  } else {
                                      setErrors("A technical error has occurred, please try again later");
                                  }
                              });
                        }
                    }
                });

                return false;
            })
        }

        const displayTooltip = () =>  {
            tooltip.classList.remove("hide");
            tooltip.classList.add("show");
        }
        const setErrors = (error: string) => {
            document.getElementById("modalContent").innerHTML = "";
            document.getElementById("modalContainer").classList.remove("form-visible");
            document.getElementById('jsModal').classList.add("modal-error");
            document.getElementById("modalTrigger").click();

            const errorDiv = document.createElement("div");
            errorDiv.id = "formErrorMessage";

            const modalContent = document.getElementById('modalContent');
            modalContent.appendChild(errorDiv);
            errorDiv.innerText = error;
        }
        const displayTooltipSuccess = (box) =>  {
            box.classList.add("success");
        }
        const removeTooltip = (message) =>  {
            if(message){
                message.classList.add("hide");
                message.classList.remove("show");
                message.classList.remove("success");
            }
        }

        const resetForm = () => {
            form6Formats.reset();
        }

    };
}

Contact.init();
