declare var Swiper: any;

try {

  const checkNumbersOfSlides = (swiper, swiperInit) =>{
    const countSlides = swiperInit.slides.length - 2;
    const swiperWrapper = swiper.getElementsByClassName('swiper-wrapper')[0];

    if(countSlides < 3 ){
      swiperWrapper.classList.add('disabled');
      swiper.classList.add('navigation-hidden')
    } else {
      swiper.classList.remove('navigation-hidden')
      swiperWrapper.classList.remove('disabled');
    }
  }

  //swiper carousel index page
  const swiperHome = document.querySelector('.swiper-container--home');
  if(swiperHome){
    const mySwiperCarousel = new Swiper('.swiper-container--home', {
      loop: true,
      effect: 'coverflow',
      grabCursor: true,
      mousewheel: false,
      centeredSlides: true,
      slidesPerView: 'auto',
      clickable: true,
      parallax: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 450,
        modifier: 1,
        slideShadows: true,
      },
      breakpoints: {
        525: {
          spaceBetween: 50,
        },
        1024: {
          spaceBetween: 100,
        },
        1200: {
          spaceBetween: 400,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      navigation: {
        nextEl: ".swiper-button-next--home",
        prevEl: ".swiper-button-prev--home",
      },
    });
  }

//swiper news
  const swiperNews = document.querySelector('.swiper-containerNews');
  if(swiperNews){
    const mySwiperNews = new Swiper('.swiper-containerNews', {
      loop: true,
      slidesPerView: 1.25,
      spaceBetween: 20,
      grabCursor: true,
      mousewheel: false,
      centeredSlides: false,
      breakpoints: {
        525: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        },
        1200: {
          slidesPerView: 4
        }
      },
      navigation: {
        nextEl: ".swiper-button-next--news",
        prevEl: ".swiper-button-prev--news",
      },
    });
    checkNumbersOfSlides(swiperNews, mySwiperNews);
  }


//swiper other Formats
  const swiperFormats = document.querySelector('.swiper-containerFormats');

  if(swiperFormats) {
    const mySwiperFormats = new Swiper('.swiper-containerFormats', {
      loop: true,
      slidesPerView: "1.25",
      spaceBetween: 20,
      grabCursor: true,
      mousewheel: false,
      centeredSlides: false,
      breakpoints: {
        525: {
          slidesPerView: 2
        },
      },
      navigation: {
        nextEl: ".swiper-button-next--formats",
        prevEl: ".swiper-button-prev--formats",
      },
    });

    checkNumbersOfSlides(swiperFormats, mySwiperFormats);
  }

// swiper our format
  const breakpoint = window.matchMedia('(min-width: 525px)');
  let ourFormats;

  const breakpointChecker = () => {
    if ( breakpoint.matches === true ) {
      if ( ourFormats !== undefined ) ourFormats.destroy( true, true );
      return;
    } else if ( breakpoint.matches === false ) {
      return enableSwiper();
    }
  };

  const enableSwiper = () => {
    ourFormats = new Swiper('.listOurFormats', {
      slidesPerView: "1.25",
      spaceBetween: 20,
      grabCursor: true,
      mousewheel: false,
    });
  };

  breakpoint.addEventListener("change", breakpointChecker);
  breakpointChecker();

} catch (err) {
  console.log(err);
}
